/*
 * @Date: 2023-02-24 10:07:14
 * @LastEditTime: 2023-08-01 17:18:44
 */
import { request } from '@/request/request';
import { parameterChange } from '@/utils';

export default {
  // 获取所有课类
  getAllCourseClass(data) {
    return request({
      url: "/course/getAllCourseClass",
      method: "post",
      data,
    });
  },

  // 获取课程、下属单元及课次信息
  getCourseInfoWithUnitMessage(data) {
    return request({
      url:
        "/course/course-info/getCourseInfoWithUnitMessage?" +
        parameterChange(data),
      method: "get",
      data,
    });
  },

  // 根据课次id获取对应知识点
  getCourseKnowledge(data) {
    return request({
      url: "/course/course-knowledge/getChapterKnowledge",
      method: "post",
      data,
    });
  },

  // 获取题库界面的知识点分类信息（多条件查询）
  getKnowledgeClassifyInfo(data) {
    return request({
      url:
        "/course/knowledge-info/getKnowledgeClassifyInfo?" +
        parameterChange(data),
      method: "get",
    });
  },

  // 获取一级知识点
  getFKByCcId(data) {
    return request({
      url: "/course/knowledge-info/getFKByCcId?" + parameterChange(data),
      method: "get",
    });
  },

  // 获取二级知识点
  getSKByFkId(data) {
    return request({
      url: "/course/knowledge-info/getSKByFkId?" + parameterChange(data),
      method: "get",
    });
  },

  // 获取提问列表数据
  getCommentList(data) {
    return request({
      url: "/course/comment/getCommentList?" + parameterChange(data),
      method: "get",
    });
  },

  // 获取提问详情列表
  getReplyList(data) {
    return request({
      url: "/course/comment/getReplyList?" + parameterChange(data),
      method: "get",
    });
  },

  // 获取课次信息（视频与试卷）
  getCourseChapter(data) {
    return request({
      url: "/course/course-chapter/getCourseChapter",
      method: "post",
      data,
    });
  },

  // 添加提问
  addComment(data) {
    return request({
      url: "/course/comment/addComment",
      method: "post",
      data,
    });
  },

  // 获取评论列表数据
  getDiscussionComment(data) {
    return request({
      url: "/course/comment/getDiscussionCommment?" + parameterChange(data),
      method: "get",
    });
  },

  // 添加评论
  addDiscussionComment(data) {
    return request({
      url: "/course/comment/addDiscussionCommment",
      method: "post",
      data,
    });
  },

  // 评论区上传图片的接口
  uploadImageQ(data) {
    return request({
      url: "/course/comment/uploadImage",
      method: "post",
      data,
    });
  },

  // 获取推荐课程
  getRecommendCourse(data) {
    return request({
      url: "/course/course-info/getRecommendCourse?" + parameterChange(data),
      method: "get",
    });
  },

  // 获取推荐课程
  getCourseStatisticsInfo(data) {
    return request({
      url:
        "/course/course-info/getCourseStatisticsInfo?" + parameterChange(data),
      method: "get",
    });
  },

  // 保存课堂练习的代码
  saveStudentWork(data) {
    return request({
      url: "/course/student-work/saveStudentWork",
      method: "post",
      data,
    });
  },

  // 获取学生的作品集
  getStudentWork(data) {
    return request({
      url: "/course/student-work/getStudentWork?" + parameterChange(data),
      method: "get",
      data,
    });
  },

  // 我的代码集下载文件
  downloadStudentWork(data) {
    return request({
      url: "/course/student-work/downloadStudentWork?" + parameterChange(data),
      method: "get",
      data,
    });
  },

  // 删除学生代码作品
  deleteStudentWork(data) {
    return request({
      url: "/course/student-work/deleteStudentWork?" + parameterChange(data),
      method: "get",
      data,
    });
  },

  // 获取代码集详细信息
  getStudentWorkById(data) {
    return request({
      url: "/course/student-work/getStudentWorkById?" + parameterChange(data),
      method: "get",
    });
  },

  // 获取学生已购买课程的学习进度
  getAllCourseStudySchedule(data) {
    return request({
      url:
        "/course/course-info/getAllCourseStudySchedule?" +
        parameterChange(data),
      method: "get",
    });
  },

  // 添加课程用户
  addCourseStu(data) {
    return request({
      url: "/course/course-info/addCourseStu",
      method: "post",
      data,
    });
  },

  // 保持课程用户
  keepCourseStu(data) {
    return request({
      url: "/course/course-info/keepCourseStu",
      method: "post",
      data,
    });
  },

  // 移除课程用户
  removeCourseStu(data) {
    return request({
      url: "/course/course-info/removeCourseStu",
      method: "post",
      data,
    });
  },

  // 获取课程知识点掌握度
  getKnowledgeInfoMastery(data) {
    return request({
      url: "/course/course-info/getKnowledgeMastery?" + parameterChange(data),
      method: "get",
      data,
    });
  },

  // 获取课类知识点掌握度
  getKnowledgeClassMastery(data) {
    return request({
      url: "/course/course-class/getKnowledgeMastery?" + parameterChange(data),
      method: "get",
      data,
    });
  },

  // 获取课程能力雷达图
  getAbilityInfoChart(data) {
    return request({
      url: "/course/course-info/getAbilityChart?" + parameterChange(data),
      method: "get",
      data,
    });
  },

  // 获取课类能力雷达图
  getAbilityClassChart(data) {
    return request({
      url: "/course/course-class/getAbilityChart?" + parameterChange(data),
      method: "get",
      data,
    });
  },

  // 获取课类详情
  getCourseClassDetail(data) {
    return request({
      url: "/course/course-class/getCourseClassDetail?" + parameterChange(data),
      method: "get",
      data,
    });
  },

  // 获取学生已购买课程课类
  getCourseClassByStuId(data) {
    return request({
      url:
        "/course/course-class/getCourseClassByStuId?" + parameterChange(data),
      method: "get",
      data,
    });
  },

  // 获取课类整体掌握度
  getClassMastery(data) {
    return request({
      url: "/course/course-class/getClassMastery?" + parameterChange(data),
      method: "get",
      data,
    });
  },

  // 获取课程整体掌握度
  getCourseMastery(data) {
    return request({
      url: "/course/course-info/getCourseMastery?" + parameterChange(data),
      method: "get",
      data,
    });
  },

  // 获取课类课程学习情况
  getClassMasteryTop(data) {
    return request({
      url: "/course/course-class/getMasteryTop?" + parameterChange(data),
      method: "get",
      data,
    });
  },

  // 获取课程知识点掌握度Top
  getInfoMasteryTop(data) {
    return request({
      url: "/course/course-info/getMasteryTop?" + parameterChange(data),
      method: "get",
      data,
    });
  },

  // 获取课程测试试卷
  getCoursePaper(data) {
    return request({
      url: "/course/course-info/getCoursePaper?" + parameterChange(data),
      method: "get",
      data,
    });
  },

  // 绑定免费课程
  bindFreeCourse(data) {
    return request({
      url: "/course/course-info/bindFreeCourse",
      method: "post",
      data,
    });
  },

  // 获取课类列表
  getCourseClassList(data) {
    return request({
      url: "/course/course-class/getCourseClassList?" + parameterChange(data),
      method: "get",
      data,
    });
  },

  // 首页-获取课类下的课程列表
  getCourseListByCcId(data) {
    return request({
      url: "/course/course-info/getCourseListByCcId?" + parameterChange(data),
      method: "get",
    });
  },

  // 我的课程
  getUserCourseStudySchedule(data) {
    return request({
      url:
        "/course/course-info/getUserCourseStudySchedule?" +
        parameterChange(data),
      method: "get",
      data,
    });
  },

  // 体验课
  getExperienceCourse(data) {
    return request({
      url: "/course/course-info/getExperienceCourse?" + parameterChange(data),
      method: "get",
      data,
    });
  },

  // 获取科目软件信息
  getSoftInfo(data) {
    return request({
      url: "/course/course-class/getSoftInfo?" + parameterChange(data),
      method: "get",
      data,
    });
  },

  // 获取下拉列表
  getList(data) {
    return request({
      url: "/course/competition/getList?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 获取下拉列表
  getTrackList(data) {
    return request({
      url: "/course/competition/getTrackList?" + parameterChange(data),
      method: "get",
      data,
    });
  },

  // 获取赛事卡片
  getCompetitionCard(data) {
    return request({
      url: "/course/competition/getCompetitionCard",
      method: "post",
      data,
    });
  },

  // 获取赛事详情
  getCompetitionDetail(data) {
    return request({
      url: "/course/competition/getCompetitionDetail?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 获取赛项卡片
  getEventCard(data) {
    return request({
      url: "/course/competition/getEventCard?" + parameterChange(data),
      method: "get",
      data,
    });
  },
  // 获取赛项详情
  getEventDetail(data) {
    return request({
      url: "/course/competition/getEventDetail?" + parameterChange(data),
      method: "get",
      data,
    });
  },
};
