import { useCommonStore } from "@/store";

/*
 * @Date: 2022-07-11 14:27:16
 * @LastEditTime: 2023-03-15 09:16:49
 */
export function getColor(value) {
  const commonStore = useCommonStore();
  const { dataBoundary } = commonStore;
  let key;
  if (value <= dataBoundary[0]) key = "red";
  else if (value <= dataBoundary[1]) key = "blue";
  else key = "green";
  return key;
}

/**
 * @description: 内容拼接
 * @param {*} data
 * @return {*}
 */
export function parameterChange(data) {
  let res = ``;
  for (const key in data) {
    let dataKey = "";
    if (data[key] || data[key] === 0) dataKey = data[key];
    res = `${res}&${key}=${dataKey}`;
  }
  return res.substr(1);
}

// 复制文本
export function copyText(text) {
  return new Promise((resolve) => {
    var textarea = document.createElement("textarea");
    textarea.value = text;
    textarea.style.position = "fixed"; // 使其在视口之外
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    resolve();
  });
}

// 将阿拉伯数字转为汉字;
export function convertToChineseNumeral(num) {
  if (num == 10) {
    return "十";
  } else if (num == 1) {
    return "一";
  }
  const digits = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
  const units = ["", "十", "百", "千", "万"];
  let result = "";
  let numStr = num.toString();
  for (let i = 0; i < numStr.length; i++) {
    let digit = parseInt(numStr.charAt(i));
    let unit = units[numStr.length - i - 1];
    if (digit === 0) {
      // 当前数字为0时不需要输出汉字，但需要考虑上一个数字是否为0，避免出现连续的零
      if (result.charAt(result.length - 1) !== "零") {
        result += "零";
      }
    } else {
      result += digits[digit] + unit;
    }
  }
  // 对于一些特殊的数字，如10、100等，需要在最前面加上“一”
  if (result.charAt(0) === "一") {
    result = result.substr(1, result.length);
  } else if (result.charAt(0) === "百") {
    result = "一" + result;
  } else if (result.charAt(0) === "千") {
    result = "一" + result;
  }
  return result;
}

// 日期格式转换
export function formattedDateRange(startDate, endDate) {
  if (startDate && endDate) {
    const startYear = startDate.slice(0, 4); // 获取起始日期的年份（YYYY）
    const startMonth = startDate.slice(5, 7); // 获取起始日期的月份（MM）
    const startDay = startDate.slice(8, 10); // 获取起始日期的日（DD）
    const endYear = endDate.slice(0, 4); // 获取结束日期的年份（YYYY）
    const endMonth = endDate.slice(5, 7); // 获取结束日期的月份（MM）
    const endDay = endDate.slice(8, 10); // 获取结束日期的日（DD）

    // 如果年份相同，格式化为 YYYY年MM月DD日~MM月DD日
    if (startYear === endYear) {
      return `${startYear}年${startMonth}月${startDay}日~${endMonth}月${endDay}日`;
    }

    // 如果年份不同，格式化为 YYYY年MM月DD日~YYYY年MM月DD日
    return `${startYear}年${startMonth}月${startDay}日~${endYear}年${endMonth}月${endDay}日`;
  }
  return "暂无";
}

//打开新的标签页
export function openEnrollEntrance(url) {
  if(url){
    window.open(url, "_blank");
  }
}
