<template>
  <div class="left_nav">
    <div class="left_nav_title">
      <slot name="title"></slot>
    </div>

    <div class="left_nav_courses">
      <template v-if="props.type == 'details'">
        <div
          v-for="(item, index) in items"
          :class="[
            'course',
            {
              course_active: chooseDetail == item.id,
            },
          ]"
          :key="item.id"
          @click="clickDetail(item.id)"
        >
          <!-- <div class="course_active_box" v-if="chooseDetail == item.id"></div> -->

          <img
            class="course_icon"
            :src="
              index == 0 && chooseDetail == item.id
                ? require('@/assets/competition/all_white.svg')
                : item.logo
            "
          />
          <div class="course_desc">
            <span class="course_name_first" v-if="index == 0">{{
              item.name
            }}</span>
            <template v-else>
              <span class="course_name">{{ item.name }}</span>
              <span class="course_age"> 适合年级：{{ item.gradeRange }} </span>
            </template>
          </div>
          <img
            class="crouse_no_choose_icon"
            src="@/assets/competition/right_green.svg"
            alt=""
          />
        </div>
      </template>
      <template v-if="props.type == 'index'">
        <template v-for="(item, index) in items" :key="index">
          <div
            class="list_top"
            :class="[
              {
                list_top_active: activeCourseId == item.name,
              },
            ]"
            @click="clickAnchor(item.name)"
          >
            <img
              class="list_left"
              :src="
                require(`@/assets/competition/${
                  isOpen(item.name) && activeCourseId == item.name
                    ? 'file_white.svg'
                    : 'file.svg'
                }`)
              "
              alt=""
            />
            <div
              class="list_text overflow-text"
              :class="[{ list_text_close: !isOpen(item.name) }]"
            >
              {{ item.name }}<span>:</span>{{ chooseData[item.name] }}
            </div>
            <img
              class="list_right"
              :src="
                require(`@/assets/competition/${
                  isOpen(item.name) && activeCourseId == item.name
                    ? 'top_white.svg'
                    : 'bottom_gray.svg'
                }`)
              "
              alt=""
            />
          </div>
          <div
            class="list_bottom"
            :class="[{ list_bottom_index: props.type === 'index' }]"
            v-show="isOpen(item.name)"
          >
            <template v-if="item.name == '赛事区域'">
              <div class="local_sqr">
                <a-select
                  class="select_new"
                  v-model:value="firstValue"
                  :options="item.children[0]"
                  placeholder="请选择省份"
                  @change="firstSelect"
                />
                <a-select
                  class="select_new"
                  v-model:value="secondValue"
                  :options="secondGroup"
                  placeholder="请选择地市"
                  @change="secondSelect"
                />
              </div>
            </template>

            <!-- <template v-if="item.name == '竞赛时间'">
              <div class="time">
                <div class="base_time">赛事开始日期</div>
                <a-date-picker
                  v-model:value="startTime"
                  :format="dateFormat"
                  placeholder="请选择开始报名日期"
                  @change="startTimeChange"
                />
                <div class="base_time">赛事结束日期</div>
                <a-date-picker
                  v-model:value="endTime"
                  :format="dateFormat"
                  placeholder="请选择决赛结束日期"
                  @change="endTimeChange"
                />
              </div>
            </template> -->
            <template v-else>
              <div
                class="list_bottom_list"
                v-for="(data, index) in item.children"
                :key="index"
                @click="clickItem(data, item.name)"
              >
                <div class="list_choose_icon">
                  <img
                    v-if="chooseData[item.name] == data"
                    src="@/assets/competition/to_choose.png"
                    alt=""
                  />
                </div>
                <div class="list_choose_text">{{ data }}</div>
              </div>
            </template>
          </div>
        </template>
      </template>
      <slot name="childDetail"></slot>
      <div class="course_bottom">
        <div class="course_bottom_flex">
          <img
            class="course_bottom_image"
            src="@/assets/practice/mascot.png"
            alt=""
          />
          <div class="course_bottom_qr">
            <img class="qr_icon" src="@/assets/competition/qr.svg" />
            <div class="qr_text">
              在信息学奥林匹克竞赛之路一路向前！争做科技特长生
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, getCurrentInstance, onMounted, nextTick } from "vue";
import dayjs from "dayjs";
const { proxy } = getCurrentInstance();
const props = defineProps({
  items: Array,
  type: String,
});

const firstValue = ref("请选择省份");
const secondValue = ref("请选择地市");
const secondGroup = ref([{ label: "全部", value: "全部" }]);
// const startTime = ref("");
// const endTime = ref("");
// const dateFormat = "YYYY年MM月D日"; // 日期格式
// const startTimeChange = (value, mode) => {
//   console.log("aaaa", startTime.value);
//   const time = dayjs(value).format("YYYY-MM-DD");
//   clickItem(mode ? time : "", "开始时间");
// };
// const endTimeChange = (value, mode) => {
//   const time = dayjs(value).format("YYYY-MM-DD");
//   clickItem(mode ? time : "", "结束时间");
// };
const firstSelect = (value) => {
  let datas = props.items;
  datas.forEach((item) => {
    if (item.name == "赛事区域") {
      const index = item.children[0].findIndex((data) => data.value === value);
      secondValue.value = "全部";
      secondGroup.value = item.children[1][index - 1];
      const topName = "赛事省份";
      clickItem(firstValue.value, topName);
    }
  });
};
const secondSelect = (value) => {
  const topName = "赛事地市";
  clickItem(secondValue.value, topName);
};
const chooseData = ref({
  赛事级别: "全部",
  晋级机制: "全部",
  赛事区域: "全部",
  参赛选手: "全部",
  所属赛道: "全部",
  比赛状态: "全部",
  // 竞赛时间: "全部",
});

const emits = defineEmits(["clickItem"]);

const openItems = ref(["赛事区域"]);
const activeCourseId = ref("赛事区域");
const chooseDetail = ref(0);
const clickAnchor = (name) => {
  activeCourseId.value = name;
  toggle(name);
};

// type: index  赛事导航点击
const clickItem = (name, topName) => {
  // 把name存入chooseData对应项中
  // if (topName == "开始时间" || topName == "结束时间") {
  //   let start = dayjs(startTime.value).format("YYYY-MM-DD") || "";
  //   let end = dayjs(endTime.value).format("YYYY-MM-DD") || "";
  //   start = start === "Invalid Date" ? "" : start;
  //   end = end === "Invalid Date" ? "" : end;

  //   chooseData.value["竞赛时间"] = start || end ? `${start} - ${end}` : "全部";
  // } else {
  //   chooseData.value[topName] = name;
  // }

  if (topName == "赛事省份" || topName == "赛事地市") {
    if (topName == "赛事省份") {
      secondValue.value = "全部";
    }
    const first =
      firstValue.value && firstValue.value != "全部" ? firstValue.value : "";
    const second =
      secondValue.value && secondValue.value != "全部" ? secondValue.value : "";
    chooseData.value["赛事区域"] =
      first || second ? `${first} ${second}` : "全部";
  } else {
    chooseData.value[topName] = name;
  }

  emits("clickItem", {
    name,
    topName,
  });
};
// type: detail  赛道导航点击
const clickDetail = (id) => {
  chooseDetail.value = id;
  emits("clickDetail", {
    id,
  });
};
const toggle = (name) => {
  if (openItems.value[0] === name) {
    // 如果当前项已经展开，则收起
    openItems.value = [];
  } else {
    // 否则，展开新项，并关闭其他项
    openItems.value = [name];
  }
};
const isOpen = (name) => {
  return openItems.value.includes(name);
};

defineExpose({ clickItem, clickDetail });
</script>

<style lang="less" scoped>
.left_nav {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.45);
  box-shadow: 1px 0px 0px 0px rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(255, 255, 255, 0.55);
  z-index: 3;
  padding: 24px 0 0 0;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;

  .left_nav_title {
    width: calc(100% - 32px);
    height: 44px;
    background: rgba(255, 255, 255, 0.9);
    box-sizing: border-box;
    margin-left: 16px;
    display: flex;
    align-items: center;
    background-color: transparent;
    padding: 20px 0 30px 0;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #dcdcdc;
  }

  .left_nav_courses {
    width: 100%;
    height: fit-content;
    margin-top: 24px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0;
    }
    .course {
      width: calc(100% - 32px);
      height: fit-content;
      cursor: pointer;
      padding: 8px 16px;
      margin: 0 16px 16px 16px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      position: relative;
      border-radius: 16px;
      border: none;
      background: rgba(255, 255, 255, 0.55);
      .course_active_box {
        width: 16px;
        height: 0;
        background: #008858;
        position: absolute;
        right: 0;
      }

      .course_icon {
        width: 72px;
        height: 72px;
        background: transparent;
        border-radius: 16px;
        border: none;
        padding: 6px;
        box-sizing: border-box;
      }

      .course_desc {
        width: 0;
        flex: 1;
        padding-left: 16px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        .course_name_first {
          font-weight: 600;
          font-size: 24px;
          color: rgba(0, 0, 0, 0.9);
          line-height: 48px;
        }

        .course_name {
          font-size: 20px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.9);
          line-height: 36px;
        }

        .course_age {
          height: 24px;
          font-size: 16px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.6);
          line-height: 24px;
          margin-top: 4px;
        }
      }

      .crouse_no_choose_icon {
        width: 28px;
        height: 28px;
        opacity: 0;
      }

      &:hover {
        padding: 8px 16px;
        background: #c6f3d7;
        box-shadow: 0px 4px 8px 0px rgba(0, 136, 88, 0.25);
        border-radius: 16px;
        border: 1px solid #008858;

        .course_icon {
          background: transparent;
          border: none;
        }

        .course_desc {
          .course_name_first {
            color: #2ba471;
          }
          .course_name {
            font-size: 20px;
            color: #2ba471;
          }

          .course_age {
            color: #2ba471;
          }
        }

        .crouse_no_choose_icon {
          opacity: 1;
        }
      }
    }
    .course_active {
      background: #2ba471;
      .course_desc {
        width: 0;
        flex: 1;
        padding-left: 16px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        .course_name_first {
          color: rgba(255, 255, 255, 0.9);
        }

        .course_name {
          color: rgba(255, 255, 255, 0.9);
        }

        .course_age {
          color: rgba(255, 255, 255, 0.9);
        }
      }
    }

    .course_bottom {
      box-sizing: border-box;
      padding: 8px 16px 76px 16px;
      .course_bottom_flex {
        background-image: url("@/assets/competition/nav_bg.svg");
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 16px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        height: 174px;
        overflow: hidden;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        align-items: center;
        .course_bottom_image {
          width: 128px;
          height: 128px;
        }
        .course_bottom_qr {
          border-left: none;
          height: 100%;
          background: rgba(255, 255, 255, 0.9);
          flex: 1;
          box-sizing: border-box;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .qr_icon {
            width: 80px;
            height: 80px;
          }
          .qr_text {
            font-weight: 500;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.9);
            line-height: 24px;
            margin-top: 8px;
            padding: 0 24px;
          }
        }
      }
    }
  }
  .list_top {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0 16px 16px 16px;
    width: calc(100% - 32px);
    height: 56px;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #e7e7e7;
    padding: 14px 16px;
    .list_left {
      width: 28px;
      height: 28px;
    }
    .list_text {
      font-weight: 600;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.6);
      line-height: 28px;
      margin-left: 8px;
      span {
        margin: 0 8px 0 2px;
      }
    }
    .list_text_close {
      color: rgba(0, 0, 0, 0.6) !important;
    }
    .list_right {
      width: 28px;
      height: 28px;
      margin-left: auto;
    }
    &:hover {
      background: #e3f9e9;
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #2ba471;
      color: #008858;
    }
  }
  .list_bottom {
    height: fit-content;
    margin: 0 16px 16px 16px;
    width: calc(100% - 32px);
    .time {
      width: 100%;
      padding: 0 32px;
      box-sizing: border-box;
      :deep(.ant-picker) {
        width: 100%;
        margin: 8px 0 24px !important;
      }
      .base_time {
        font-weight: 500;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.9);
        line-height: 28px;
      }
    }
    .local_sqr {
      width: 100%;
      box-sizing: border-box;
      border-bottom: 1px solid #dcdcdc;
      padding: 0px 16px;
      font-weight: 500;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.9);
      line-height: 28px;
      .select_new {
        width: 100%;
        margin-bottom: 10px;
        // height: 40px;
      }
    }
    .list_bottom_list {
      cursor: pointer;
      height: 56px;
      width: 100%;
      box-sizing: border-box;
      border-bottom: 1px solid #dcdcdc;
      padding: 14px 16px;
      font-weight: 500;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.9);
      line-height: 28px;
    }
  }
  .list_bottom_index {
    margin: 0 0 16px 0 !important;
    width: 100%;

    .list_bottom_list {
      padding: 14px 32px;
      display: flex;
      align-items: center;
      .list_choose_icon {
        img {
          width: 100%;
          height: 100%;
        }
        width: 28px;
        height: 28px;
      }
      .list_choose_text {
        margin-left: 8px;
        font-weight: 500;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.9);
      }
      &:hover {
        color: #008858;
        background: #c6f3d7;
      }
    }
  }
}
.list_top_active {
  background: #2ba471 !important;
  border: 1px solid #008858;
  .list_text {
    color: rgba(255, 255, 255, 0.9) !important;
  }
}

.overflow-text {
  white-space: nowrap; /* 禁止文本换行 */
  overflow: hidden; /* 超出部分隐藏 */
  text-overflow: ellipsis; /* 使用省略号表示溢出的部分 */
}
</style>
