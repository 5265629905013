/*
 * @Date: 2023-02-14 09:56:14
 * @LastEditTime: 2024-06-06 18:31:42
 */
import BasicLayout from '@/layout/BasicLayout';

let children = [
  {
    path: "competition",
    name: "competition",
    redirect: "/competition/track",
    children: [
      {
        path: "track",
        name: "track",
        component: () => import("@/views/competition/index.vue"),
      },
      {
        path: "detail",
        name: "detail",
        component: () => import("@/views/competition/details.vue"),
      },
      {
        path: "childDetail",
        name: "childDetail",
        component: () => import("@/views/competition/childDetails.vue"),
      },
      {
        path: "introduces",
        name: "introduces",
        component: () => import("@/views/competition/introduce.vue"),
      },
    ],
  },
  {
    path: "classification",
    name: "classification",
    redirect: "/classification/category",
    children: [
      {
        path: "category",
        name: "category",
        component: () => import("@/views/classification/index.vue"),
      },
      {
        path: "content",
        name: "content",
        component: () => import("@/views/classification/content.vue"),
      },
    ],
  },
  {
    path: "courses",
    name: "courses",
    redirect: "/courses/learn",
    children: [
      {
        path: "learn",
        name: "learn",
        meta: { breadcrumb: true },
        component: () => import("@/views/courses/learn/index.vue"),
      },
      {
        path: "practice",
        name: "practice",
        meta: { breadcrumb: true, isHideMyCourseBtn: true },
        component: () => import("@/views/courses/practice/index.vue"),
      },
      {
        path: "trials",
        name: "trials",
        meta: { breadcrumb: true, isHideMyCourseBtn: true },
        component: () => import("@/views/courses/practice/trials.vue"),
      },
      {
        path: "exam",
        name: "exam",
        meta: { breadcrumb: true, isHideMyCourseBtn: true },
        component: () => import("@/views/courses/exam/paper/index.vue"),
      },
      {
        path: "summary",
        name: "summary",
        meta: { breadcrumb: true, isHideMyCourseBtn: true },
        component: () => import("@/views/courses/exam/summary.vue"),
      },
      {
        path: "reminder",
        name: "reminder",
        meta: { breadcrumb: true, isHideMyCourseBtn: true },
        component: () => import("@/views/courses/exam/reminder.vue"),
      },
      {
        path: "intro",
        name: "intro",
        component: () => import("@/views/courses/introAndBuy/intro/index.vue"),
      },
      // {
      //   path: 'buy',
      //   name: 'buy',
      //   component: () =>
      //     import('@/views/courses/introAndBuy/buy/index.vue'),
      // },
      // {
      //   path: 'result',
      //   name: 'result',
      //   component: () =>
      //     import('@/views/courses/introAndBuy/buy/result.vue'),
      // },
    ],
  },
  {
    path: "capacity",
    name: "capacity",
    children: [
      {
        path: "/exercise",
        name: "exercise",
        component: () => import("@/views/exercise/index.vue"),
        redirect: "/exercise/game",
        children: [
          {
            path: "knowledge",
            name: "knowledge",
            meta: { pName: "exercise" },
            component: () => import("@/views/exercise/knowledge.vue"),
          },
          {
            path: "game",
            name: "game",
            meta: { pName: "exercise" },
            component: () => import("@/views/exercise/game.vue"),
          },
          {
            path: "grade",
            name: "grade",
            meta: { pName: "exercise" },
            component: () => import("@/views/exercise/grade.vue"),
          },
        ],
      },
      {
        path: "/exercise/contestDetail",
        name: "exerciseDetail",
        component: () => import("@/views/exercise/detail/index.vue"),
      },
      {
        path: "/exercise/contestList",
        name: "exerciseList",
        component: () => import("@/views/exercise/contestList.vue"),
      },
    ],
  },
];

if (process.env.VUE_APP_GAME == 0) {
  const arr = [
    {
      path: 'studyCenter',
      redirect: '/studyCenter/entrance',
      children: [
        {
          path: 'entrance',
          name: 'studyCenter',
          component: () => import('@/views/studyCenter/index.vue'),
        },
        {
          path: 'myCourse',
          name: 'myCourse',
          component: () => import('@/views/studyCenter/myCourse/index.vue'),
        },
        {
          path: 'academicAssessment',
          name: 'academicAssessment',
          component: () =>
            import('@/views/studyCenter/academicAssessment/index.vue'),
        },
        {
          path: 'learningFiles',
          name: 'learningFiles',
          redirect: '/studyCenter/learningFiles/courseLearning',
          children: [
            {
              path: 'courseLearning',
              name: 'courseLearning',
              meta: { pName: 'learningFiles' },
              component: () =>
                import(
                  '@/views/studyCenter/learningFiles/courseLearning/index.vue'
                ),
            },
            {
              path: 'brushLearning',
              name: 'brushLearning',
              meta: { pName: 'learningFiles' },
              component: () =>
                import(
                  '@/views/studyCenter/learningFiles/brushLearning/index.vue'
                ),
            },
            {
              path: 'learningCredits',
              name: 'learningCredits',
              meta: { pName: 'learningFiles' },
              component: () =>
                import(
                  '@/views/studyCenter/learningFiles/learningCredits/index.vue'
                ),
            },
          ],
        },
        {
          path: 'capsule',
          name: 'capsule',
          redirect: '/studyCenter/capsule/main',
          children: [
            {
              path: 'main',
              name: 'main',
              component: () => import('@/views/studyCenter/capsule/main.vue'),
            },
            {
              path: 'year',
              name: 'year',
              component: () => import('@/views/studyCenter/capsule/year.vue'),
            },
            {
              path: 'month',
              name: 'month',
              component: () => import('@/views/studyCenter/capsule/month.vue'),
            },
            {
              path: 'list',
              name: 'list',
              component: () => import('@/views/studyCenter/capsule/list.vue'),
            },
            {
              path: 'launch',
              name: 'launch',
              component: () => import('@/views/studyCenter/capsule/launch.vue'),
            },
            {
              path: 'details',
              name: 'details',
              component: () =>
                import('@/views/studyCenter/capsule/details.vue'),
            },
          ],
        },
        {
          path: 'studyRecord',
          name: 'studyRecord',
          redirect: '/studyCenter/studyRecord/wrongQuestion',
          children: [
            {
              path: 'wrongQuestion',
              name: 'wrongQuestion',
              component: () =>
                import(
                  '@/views/studyCenter/studyRecord/wrongQuestion/index.vue'
                ),
            },
            {
              path: 'work',
              name: 'work',
              component: () =>
                import('@/views/studyCenter/studyRecord/work/index.vue'),
            },
            {
              path: 'codeSet',
              name: 'codeSet',
              component: () =>
                import('@/views/studyCenter/studyRecord/codeSet/index.vue'),
            },
          ],
        },
        {
          path: 'personalCenter',
          name: 'personalCenter',
          children: [
            {
              path: 'personalInfo',
              name: 'personalInfo',
              component: () =>
                import('@/views/personalCenter/personalInfo/index.vue'),
            },
            {
              path: 'accountSettings',
              name: 'accountSettings',
              component: () =>
                import('@/views/personalCenter/accountSettings/index.vue'),
            },
            {
              path: 'orderList',
              name: 'orderList',
              component: () =>
                import('@/views/personalCenter/orderList/index.vue'),
            },
          ],
        },
      ],
    },
  ];
  children = [...children, ...arr];
}

export default [
  {
    path: "/",
    redirect:
      // process?.env.VUE_APP_GAME == 0 ? '/classification' : '/exercise/game',
      process?.env.VUE_APP_GAME == 0
        ? "/competition/track"
        : "/competition/track",
    component: BasicLayout,
    children: children,
  },
];
