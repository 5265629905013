<template>
  <div class="label">
    <template v-if="regionList.length">
      <div class="box" v-for="(item, index) in props.regionList" :key="index">
        {{ item }}
      </div>
    </template>
    <template v-if="playerList.length">
      <div
        class="box_blue"
        v-for="(item, index) in props.playerList"
        :key="index"
      >
        {{ item }}
      </div>
    </template>
  </div>
</template>
<script setup>
import { ref } from "vue";
const props = defineProps({
  playerList: {
    type: Array,
    default: [],
  },
  regionList: {
    type: Array,
    default: [],
  },
});
</script>
<style lang="less" scoped>
.label {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  .box {
    background: #fff1d1;
    border-radius: 9999px;
    border: 1px solid rgba(139, 87, 42, 0.55);

    font-weight: 600;
    font-size: 13px;
    color: #8b572a;
    line-height: 22px;
    padding: 2px 12px;
  }
  .box_blue {
    background: #f2f3ff;
    border-radius: 9999px;
    border: 1px solid #366ef4;
    font-weight: 600;
    font-size: 13px;
    color: #366ef4;
    line-height: 22px;
    padding: 2px 12px;
  }
}
</style>
