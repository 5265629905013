<template>
  <div class="tap" v-for="(item, index) in props.groupList" :key="index">
    <div class="text">{{ item.group }}</div>
    <div class="detail">{{ item.grade}}</div>
  </div>
</template>
<script setup>
const props = defineProps({
  groupList: {
    type: Array,
    default: [],
  },
});
</script>
<style lang="less" scoped>
.tap {
  display: flex;
  height: 24px;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  width: fit-content;

  .text {
    background: #366ef4;
    border-radius: 4px 0px 0px 4px;
    color: rgba(255, 255, 255, 0.9);
    padding: 0 8px;
  }
  .detail {
    background: #f2f3ff;
    border-radius: 0px 4px 4px 0px;
    border: 1px solid #366ef4;
    color: #366ef4;
    padding: 0 8px;
  }
}
</style>
