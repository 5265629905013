<template>
  <div
    :class="['course_card', `course_card_${courseInfo?.courseState}`]"
    ref="cardRef"
    @click="clickBtn(0)"
  >
    <div class="card_top">
      <slot name="cardBg">
        <img class="card_bg" src="@/assets/bg.png" />
      </slot>

      <div class="top_desc">
        <p class="top_desc_top" v-if="props.type === 'details'">
          <slot name="top"></slot>
        </p>
        <p class="title" v-if="props.type === 'details'">
          <slot name="title">占位标题</slot>
        </p>
        <!-- <p class="tips">
          <slot name="subTitle">占位二级标题</slot>
        </p> -->
        <div class="end_time">
          <slot name="middle">占位中间描述</slot>
        </div>
        <p class="grade" v-if="props.type === 'details'">
          <slot name="grade">占位中间描述</slot>
        </p>
        <p class="comment" v-if="props.type === 'details'">
          <slot name="comment"></slot>
        </p>
      </div>
    </div>
    <div
      class="card_bottom"
      :class="[{ card_bottom_height: props.type === 'index' }]"
    >
      <p class="event">
        <slot name="event"></slot>
      </p>
      <p class="level">
        <slot name="level"></slot>
      </p>
      <p class="describe">
        <slot name="describe"></slot>
      </p>
      <p class="order">
        <slot name="order"></slot>
      </p>
    </div>
  </div>
</template>

<script setup>
import { useUserStore } from "@/store";
import { ref, reactive, getCurrentInstance, onMounted } from "vue";

const { proxy } = getCurrentInstance(),
  userStore = useUserStore();

const props = defineProps({
  courseInfo: Object,
  type: { type: String, default: "index" },
  isShowCount: { type: Boolean, default: true },
});
const cardRef = ref();
const isShowSchedule = ref(false);

const btnTextMap = {
  0: "继续学习",
  1: "继续学习",
  2: "复习巩固",
  3: "重新购买",
  // 4: '购买课程',
  4: "申请开通",
};

onMounted(() => {
  // const dom = cardRef.value;
  // dom.addEventListener("mouseover", function () {
  //   if (![3, 4].includes(props.courseInfo.courseState) && userStore.token)
  //     isShowSchedule.value = true;
  // });
  // dom.addEventListener("mouseout", function () {
  //   if (![3, 4].includes(props.courseInfo.courseState) && userStore.token)
  //     isShowSchedule.value = false;
  // });
});

const emits = defineEmits(["clickBtn"]);
const clickBtn = (e) => {
  emits("clickBtn", { type: e, data: props.courseInfo });
};
</script>

<style lang="less" scoped>
.course_card {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.65);

  .card_top {
    width: 100%;
    height: 261px;
    position: relative;
    border-radius: 16px 16px 0 0;
    overflow: hidden;

    .card_bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .right_top_tag {
      width: 125px;
      height: 125px;
      position: absolute;
      top: -62px;
      right: -62px;
      transform: rotate(45deg);
      display: flex;
      align-items: flex-end;
      justify-content: center;

      span {
        display: block;
        text-align: center;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 600;
        line-height: 26px;
      }
    }

    .top_desc {
      width: 100%;
      height: 100%;
      position: relative;
      padding: 25px 0 0 0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      .top_desc_top {
        display: flex;
        align-items: center;
        padding: 0 24px;
      }
      .title {
        height: fit-content;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 20px;
        color: rgba(255, 255, 255, 0.9);
        line-height: 28px;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12),
          0px 4px 5px rgba(0, 0, 0, 0.08), 0px 1px 10px rgba(0, 0, 0, 0.05);
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin: 0 24px;
        margin-top: 8px;
      }

      .tips {
        height: fit-content;
        font-size: 18px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.9);
        line-height: 26px;
        text-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
        text-stroke: 2px rgba(0, 0, 0, 0.15);
        font-style: normal;
        text-transform: none;
        -webkit-text-stroke: 2px rgba(0, 0, 0, 0.15);
        margin: 0 24px;
        margin-top: 8px;
      }
    }
    .end_time {
      height: 22px;
      display: flex;
      align-items: flex-end;
      box-sizing: border-box;
      color: #d54941;
      margin: 0 24px;
      flex: 1;
    }
    .grade {
      height: fit-content;
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;
      gap: 8px;
      margin: 8px 24px;
    }
    .comment {
      
    }
  }

  .card_bottom {
    width: 100%;
    height: 121px;
    padding: 16px 24px 0 24px;
    box-sizing: border-box;
    border-radius: 0 0 16px 16px;
    background-color: rgba(256, 256, 256, 0.9);
    overflow: hidden;
    .describe {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
    }
    .order {
      display: flex;
      justify-content: space-between;
    }
  }

  &.course_card_2 {
    .card_top {
      .right_top_tag {
        background: #c6f3d7;

        span {
          color: #008858;
        }
      }
    }

    .card_bottom {
      .schedule {
        .study {
          .study_schedule,
          .study_mastery {
            color: #2ba471;
          }
        }

        .btn {
          background: #2ba471;
          border: 1px solid #2ba471;
        }
      }
    }
  }

  &.course_card_0,
  &.course_card_1 {
    .card_top {
      position: relative;

      .right_top_tag {
        background: #fff1e9;

        span {
          color: #e37318;
        }
      }
    }

    .card_bottom {
      .schedule {
        .study {
          .study_schedule,
          .study_mastery {
            color: #e37318;
          }
        }

        .btn {
          background: #e37318;
          color: rgba(255, 255, 255, 0.9);
          transition: all ease;

          &:hover {
            background: linear-gradient(270deg, #e37318 0%, #fa9550 100%);
            box-shadow: 0 4px 10px 0 rgba(250, 149, 80, 0.85);
            border: 1px solid #e37318;
          }
        }
      }
    }
  }

  &.course_card_3 {
    .card_top {
      position: relative;

      .right_top_tag {
        background: #f7fcf1;

        span {
          color: rgba(0, 0, 0, 0.4);
        }
      }
    }

    .card_bottom {
      .schedule {
        .study {
          .study_schedule,
          .study_mastery {
            color: #d54941;
          }
        }

        .btn {
          background: linear-gradient(270deg, #d54941 0%, #f6685d 100%);

          &:hover {
            box-shadow: 0 4px 10px 0 rgba(246, 104, 93, 0.85);
          }
        }
      }
    }
  }

  &.course_card_4 {
    .card_bottom {
      .schedule {
        .btn {
          width: fit-content;
          font-size: 16px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          color: #d54941;
          line-height: 28px;
          background: unset;
          box-shadow: unset;

          .buy_icon {
            width: 26px;
            height: 26px;
            margin-right: 8px;
          }

          &:hover {
            background: linear-gradient(270deg, #d54941 0%, #f6685d 100%);
            box-shadow: 0 4px 10px 0 rgba(246, 104, 93, 0.85);
            border-radius: 16px 16px 16px 16px;
            border: 1px solid #d54941;
            color: rgba(255, 255, 255, 0.9);
            font-size: 14px;

            .buy_icon {
              width: 16px;
              height: 16px;
              // content: url('@/assets/classification/buy_hover.svg');
              content: url("@/assets/classification/user_hover.png");
            }
          }
        }
      }
    }
  }

  &:hover {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.1),
      0 8px 10px 1px rgba(0, 0, 0, 0.06), 0 3px 14px 2px rgba(0, 0, 0, 0.05);

    .card_top {
      border-left: 4px solid #fff;
      border-top: 4px solid #fff;
      border-right: 4px solid #fff;
    }
  }
}
.card_bottom_height {
  height: 226px !important;
}
</style>
